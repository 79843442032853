const defaultData = require("./static-reviews.json")

const staticRecords = defaultData.map(obj => obj.fields)

const rummyHindiData = require("./rummy-hindi.json")
const rummyData = require("./rummy.json")

const rummyRecords = rummyData.map(obj => obj.fields)
const rummyHindiRecords = rummyHindiData.map(obj => obj.fields)

const carromData = require("./carrom.json")

const carromRecords = carromData.map(obj => obj.fields)

const triviaData = require("./trivia.json")

const triviaRecords = triviaData.map(obj => obj.fields)

const fruitkaatData = require("./fruitkaat.json")

const fruitkaatRecords = fruitkaatData.map(obj => obj.fields)

const cardsHindiData = require("./cards-hindi.json")

const cardsData = require("./cards.json")
const cardsMarathiData = require("./cards-marathi.json")

const cardsRecords = cardsData.map(obj => obj.fields)
const cardsHindiRecords = cardsHindiData.map(obj => obj.fields)
const cardsMarathiRecords = cardsMarathiData.map(obj => obj.fields)

const getStaticReviews = (page, lang) => {
  if (lang === "hindi") {
    switch (page) {
      case "cards":
        return cardsHindiRecords
      case "rummy":
        return rummyHindiRecords
      case "carrom":
        return carromRecords
      case "fruitkaat":
        return fruitkaatRecords
      case "trivia":
        return triviaRecords
      default:
        return staticRecords
    }
  } else if (lang === "marathi") {
    switch (page) {
      case "cards":
        return cardsMarathiRecords
      case "rummy":
        return rummyRecords
      case "carrom":
        return carromRecords
      case "fruitkaat":
        return fruitkaatRecords
      case "trivia":
        return triviaRecords
      default:
        return staticRecords
    }
  } else {
    switch (page) {
      case "cards":
        return cardsRecords
      case "rummy":
        return rummyRecords
      case "carrom":
        return carromRecords
      case "fruitkaat":
        return fruitkaatRecords
      case "trivia":
        return triviaRecords
      default:
        return staticRecords
    }
  }
}

export { getStaticReviews }
