import React from "react"
import "../styles/AppInfo.css"

class AppInfo extends React.Component {
  render() {
    return (
      <>
        <div className="container appInfo-container">
          <p className="weight500">App Info</p>
          <ul className="appInfoWrap is-size-7 has-text-grey">
            <li>
              <span>Update Date</span>
              <span>Oct 2021</span>
            </li>
            <li>
              <span>Number of Installs</span>
              <span>50,00,000+</span>
            </li>
            <li>
              <span>Download Size</span>
              <span>20 MB</span>
            </li>
            <li>
              <span>Released On</span>
              <span>Jan 2018</span>
            </li>
            <li>
              <span>Offered By</span>
              <span>Megashots Internet Private Limited</span>
            </li>
          </ul>
        </div>
      </>
    )
  }
}

export default AppInfo
