const getPastDate = pastByDays => {
  if (isNaN(pastByDays)) {
    pastByDays = 0
  }
  let date = new Date()
  let temp = date - 1000 * 60 * 60 * 24 * parseInt(pastByDays) // current date's milliseconds - 1,000 ms * 60 s * 60 mins * 24 hrs * (# of days beyond one to go back)
  let pastDate = new Date(temp)

  let MyDateString
  MyDateString =
    ("0" + pastDate.getDate()).slice(-2) +
    "/" +
    ("0" + (pastDate.getMonth() + 1)).slice(-2) +
    "/" +
    pastDate.getFullYear()
  return MyDateString
}
export { getPastDate }
