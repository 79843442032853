import React from "react"
import "../styles/stats.css"
import { StaticImage } from "gatsby-plugin-image"
import star from "../images/star.svg"
import ConfigContext from "./utils/configContext"

class Stats extends React.Component {
  static contextType = ConfigContext

  constructor(props) {
    super(props)
    this.state = {
      oneLink: "",
    }
  }

  nonChromeFunc = () => {
    this.props.toggleModal()
    setTimeout(() => {
      window.location = this.state.oneLink || this.props.installUrl
    }, 1100)
  }

  onClickHandler = eventSource => {
    this.props.fbEventAndShowModal(eventSource)
    this.props.isChrome ? this.props.togglePrompt() : this.nonChromeFunc()
  }

  render() {
    return (
      <>
        <div className="stats-reviews">
          <a href={"#reviews"}>
            <div className="ratingDiv">
              <p className="topText has-text-grey-darker">
                <span
                  style={{ marginRight: "-4px" }}
                  className="weight-700 is-size-7 has-text-grey-darker"
                >
                  {this.context.ratingValue || "4.8"}
                </span>
                <span className="icon has-text-grey-darker">
                  <img className="ratingStar" src={star} alt="" />
                </span>
              </p>
            </div>
            <p
              style={{ marginTop: "-4px" }}
              className="is-size-7 has-text-grey"
            >
              {this.context.totalReviews || "25.1K Reviews"}
            </p>
          </a>
        </div>

        <div
          className="stats-install install_button"
          data-onelink=""
          onClick={e => {
            this.setState({ oneLink: e.target.getAttribute("data-onelink") })
            this.onClickHandler("Icon Button")
          }}
          onKeyDown={e => {
            this.setState({ oneLink: e.target.getAttribute("data-onelink") })
            this.onClickHandler("Icon Button")
          }}
          role="button"
          tabIndex="0"
        >
          <div className="downloadDivParent">
            <div className="downloadDivChild weight-700">
              <StaticImage
                className="downloadIc"
                src="../images/download_icon.png"
                alt="download"
              />
            </div>
          </div>
          <p className="is-size-7 has-text-grey has-text-centered">
            {this.context.downloadSize || "27 MB"}
          </p>
        </div>
        <div className="stats-downloads">
          <StaticImage
            width={20}
            height={14}
            className="ratedIcon"
            src="../images/18plus.png"
            alt="18 plus"
          />

          <p className="is-size-7 has-text-grey has-text-centered">
            {"Rated for 18+"}
          </p>
        </div>
        {/* {this.context.numOfDownloads && (
          <div className="stats-downloads">
            <p className="weight-700 is-size-7 has-text-grey-darker has-text-centered">
              {this.context.numOfDownloads}
            </p>
            <p className="is-size-7 has-text-grey has-text-centered">
              {this.context.downloadsText || "Downloads"}
            </p>
          </div>
        )} */}
      </>
    )
  }
}

export default Stats
