/*
 Add pages in this object
 All carousel images should go into the respective folder on src/images/banners/
 All popup images should go into src/images/popup/hero/
 Add custom static reviews in src/static-reviews/staticReviews.js
 Note: Outbrain scripts are removed for now - july 31, 2023
*/
const pages = {
  cards: {
    gameName: "Poker",
    descriptionText:
      "Play Cards games on India’s most loved real money entertainment platform. 10000+ players are playing every day. Choose your contest / table, small stakes or big stakes. Unlimited Real Cash to be won!",
    descriptionList: [
      "Discover India’s biggest cards tables ♠️ ❤️",
      "Play with sirf 100% verified players, no fake profiles 👩 👨",
      "Choose stakes apni marzi ke - small, medium, large, & HUGE 🏆",
      "Khelo kabhi bhi, with non-stop 24-hours tables ⏳",
      "Kisi bhi issue ke liye high-priority chat support 👨🏽‍💻",
      "Support karo 100% made in India app 🇮🇳",
    ],
    gamesType: "Mega Poker - Play & Win Real Cash",
    staticTags: ["Card", "Poker", "Win Money"],
    reviewTags: [
      "Fastest Withdrawals",
      "Genuine Players",
      "Addictive",
      "Multiplayer",
      "Realistic",
    ],
    popupImage: "poker-girl.png",
    popupTitle: "Don't miss out on your chance to win big!",
    popupText:
      "Get 500% bonus cash on your first deposit. Next tournament will start in:",
    vimeoVideoId: "537657601",
    gtagId: "AW-718511341",
    airtableName: "Poker LP Reviews",
    airtableBaseId: "appNqwhCawaU6zPpv",
    staticReviews: "cards",
    numOfDownloads: "50,00,000+",
    gameCategory: "poker",
    downloadSize: "47.2 MB",
    hasLargeDescription: false,
    makeReviewsCollapsable: true,
    showGlPokerScript: false,
    appVersion: "2.5",
    latestRelease: "16 Feb 2024",
    largeDescription:
      "<p>Enjoy the best of skill-based card games with real money prizes. We believe in safe, reliable, & responsible gaming and have built a platform where trust is of utmost importance. Mega Poker allows only 100% verified profiles and features only legal, skill-based games. No luck, no chance; put your skills to work and earn money by playing card games!</p>" +
      "<h3>Why Join Mega Poker?</h3>" +
      "<p>The famous poker game, as you know it, is one of the most widely played card games in the world with millions of players and enthusiasts. And now, you can start playing this traditional game of poker online, from any location, at any time and against actual skilful opponents on the Mega Poker app.</p>" +
      "<p>By choosing to play poker online on Mega Poker, you will enter the world of a thrilling experience, comprising actual challenges, and proceed through a self-learning curve, despite your playing level. This means even if you are a beginner, intermediate or professional level player, you will quite enjoy playing the poker game online on Mega Poker with its perfectly established gameplay mechanics and guarantee of fair gameplay for all participating players!</p>" +
      "<h3>Features of the App</h3>" +
      "<ul>" +
      "<li><strong>Ideal for Both Intermediate and Advanced Players-</strong> Mega Poker offers the ideal playing environment for players to participate in the astonishingly well-designed, balanced online cash game, which demands perfect mastery to advance. However, it's also the ideal setting for a beginner to begin, meet opponents, engage in competition, and develop as a player.</li>" +
      "<li><strong>Rapid Matchmaking-</strong> You can experience a rapid matchmaking system with the Mega Poker app so that you can start playing without losing any time! You won't ever run out of worthy opponents to test out and refine your game skills as you progress thanks to the large and active user community of the online poker game on Mega Poker.</li>" +
      "<li><strong>Instant Withdrawal-</strong> Mega Poker offers instant withdrawal and fair gameplay and are legally certified with more than 20 million app downloads. Transfer your winnings in just a minute through UPI, or to your Paytm wallet, or directly to your bank account. Get faster-than-industry cash-outs via safe and secure payment channels, only on Mega Poker!</li>" +
      "</ul>" +
      "<h3>Safety and Legality</h3>" +
      "<p>At Mega Poker, we think it's important to be completely open and honest about our safety practices so you can concentrate on playing on the system without distractions. Mega Poker allows you to play various types of games with a license thus making gambling legal in India.</p>" +
      "<p><strong>Legally Certified-</strong> Mega Poker has been duly certified by national and international bodies such as Random Number Generation (RNG) Certificate, All India Gaming Federation (AIGF) MEMBER, Poker Game Fairness Certificate.</p>" +
      "<p><strong>Safe Withdrawals-</strong> To provide the safest and most reliable gaming experience possible online, we have established secure payment channels for quick withdrawals, uphold the highest standards of data protection and privacy, and use approved gameplay mechanics. To comply with applicable laws and our internal policies, and keeping inline with industry practices, we mandatorily require you to complete KYC (Know Your Customer) and provide a proof of identity.</p>" +
      "<p><strong>Personal Information-</strong> Your personal information ('Personal Information') that we collect on our platform is completely confidential to us. We have very strict procedures in place to protect your data. A significant component of the Terms of Use is this Privacy Policy.</p>" +
      "<p><strong>Certified Games-</strong> Mega Poker is RNG Certified. RNG is the system used to create unpredictable patterns that generate a completely random distribution of cards for the Poker or Rummy games online. Functionally tested, reviewed and certified games ensure that everything is exactly where it is supposed to be, the only thing you only have to think about is playing the game and not worry about anything else.</p>",
    showAppDetails: false,
    faqsVisible: true,
    showPokerSmartScript: false,
    showSnapScript: false,
    showQuoraScript: false,
    variants: {
      default: {
        installUrl: "https://www.getmega.com/poker-online-lp-apk/",
        forceUse: true,
        hostingSite: "download-app-cards", //poker-online.getmega.com
        websiteSubdir: "poker-online",
        taboolaPixelTracking: false,
        outbrainPixelTracking: false,
        fbPixelTracking: true,
        descriptionText:
          "Mega Poker offers you a really one-of-a-kind entertainment experience by combining the fun of gaming with the thrill of real money. You can play your preferred skill-based card game exclusively on Mega Poker, the best real money gaming site in India!!",
        hasLargeDescription: true,
        showAppDetails: true,
        showDownloadVideo: false,
        gameCategory: "poker",
        showPokerSmartScript: true,
        smartscriptValue: "Landing_Page_Online",
      },
      gl: {
        installUrl: "https://www.getmega.com/poker-lp-apk/",
        forceUse: true,
        hostingSite: "download-app-cards-gl", // poker.getmega.com
        websiteSubdir: "poker-new",
        taboolaPixelTracking: false,
        outbrainPixelTracking: false,
        fbPixelTracking: true,
        descriptionText:
          "Mega Poker offers you a really one-of-a-kind entertainment experience by combining the fun of gaming with the thrill of real money. You can play your preferred skill-based card games such as Poker exclusively on Mega Poker, the best real money gaming site in India!!",
        hasLargeDescription: true,
        showAppDetails: true,
        showDownloadVideo: false,
        gameCategory: "poker",
        showPokerSmartScript: true,
        smartscriptValue: "Landing_Page_New",
      },
      fb_1: {
        installUrl: "https://www.getmega.com/best-poker-lp-apk/",
        forceUse: true,
        hostingSite: "download-1-app-cards", // best-poker.getmega.com
        websiteSubdir: "poker-best",
        descriptionText:
          "Mega Poker offers you a really one-of-a-kind entertainment experience by combining the fun of gaming with the thrill of real money. You can play your preferred skill-based card game exclusively on Mega Poker, the best real money gaming site in India!!",
        hasLargeDescription: true,
        taboolaPixelTracking: false,
        outbrainPixelTracking: false,
        fbPixelTracking: true,
        showAppDetails: true,
        showDownloadVideo: false,
        showPokerSmartScript: true,
        gameCategory: "poker",
        smartscriptValue: "Landing_Page_Best",
      },
      fb_2: {
        installUrl: "https://www.getmega.com/poker-game-lp-apk/",
        forceUse: true,
        hostingSite: "download-2-app-cards", // poker-game.getmega.com
        websiteSubdir: "poker-game",
        descriptionText:
          "Mega Poker offers you a really one-of-a-kind entertainment experience by combining the fun of gaming with the thrill of real money. You can play your preferred skill-based card game exclusively on Mega Poker, the best real money gaming site in India!!",
        hasLargeDescription: true,
        taboolaPixelTracking: false,
        outbrainPixelTracking: false,
        fbPixelTracking: true,
        showAppDetails: true,
        showDownloadVideo: false,
        gameCategory: "poker",
        showPokerSmartScript: true,
        smartscriptValue: "Landing_Page_Game",
      },
      fb_3: {
        installUrl: "https://www.getmega.com/poker-app-lp-apk/",
        forceUse: true,
        hostingSite: "download-3-app-cards", // poker-app.getmega.com
        websiteSubdir: "poker-app",
        descriptionText:
          "Mega Poker offers you a really one-of-a-kind entertainment experience by combining the fun of gaming with the thrill of real money. You can play your preferred skill-based card game exclusively on Mega Poker, the best real money gaming site in India!!",
        hasLargeDescription: true,
        taboolaPixelTracking: false,
        outbrainPixelTracking: false,
        fbPixelTracking: true,
        showAppDetails: true,
        showDownloadVideo: false,
        gameCategory: "poker",
        showPokerSmartScript: true,
        smartscriptValue: "Landing_Page_App",
      },
      affiliate: {
        installUrl: "https://www.getmega.com/affiliates-poker-lp-apk/",
        forceUse: true,
        hostingSite: "affiliate-download-app-cards", // affiliates-poker.getmega.com
        websiteSubdir: "poker-affiliates",
        descriptionText:
          "Mega Poker offers you a really one-of-a-kind entertainment experience by combining the fun of gaming with the thrill of real money. You can play your preferred skill-based card game exclusively on Mega Poker, the best real money gaming site in India!!",
        hasLargeDescription: true,
        taboolaPixelTracking: false,
        outbrainPixelTracking: false,
        fbPixelTracking: true,
        showAppDetails: true,
        showDownloadVideo: false,
        showSnapScript: true,
        showQuoraScript: true,
        gameCategory: "poker",
      },
      affiliate_ios: {
        installUrl:
          "https://apps.apple.com/in/app/mega-poker-play-texas-holdem/id1621029521",
        forceUse: true,
        hostingSite: "affiliate-download-app-cards-ios",
        websiteSubdir: "poker-ios",
        descriptionText:
          "Mega Poker offers you a really one-of-a-kind entertainment experience by combining the fun of gaming with the thrill of real money. You can play your preferred skill-based card game exclusively on Mega Poker, the best real money gaming site in India!!",
        hasLargeDescription: true,
        taboolaPixelTracking: false,
        outbrainPixelTracking: false,
        fbPixelTracking: true,
        showAppDetails: false,
        faqsVisible: false,
        showDownloadVideo: false,
        showSnapScript: true,
        showQuoraScript: true,
        gameCategory: "poker",
        installText: "Download App",
        gameDevice: "ios",
      },
      affiliate_ios_redirect: {
        installUrl:
          "https://apps.apple.com/in/app/mega-poker-play-texas-holdem/id1621029521",
        forceUse: true,
        hostingSite: "affiliate-cards-mega-ios",
        websiteSubdir: "mega-poker-ios",
        descriptionText:
          "Mega Poker offers you a really one-of-a-kind entertainment experience by combining the fun of gaming with the thrill of real money. You can play your preferred skill-based card game exclusively on Mega Poker, the best real money gaming site in India!!",
        hasLargeDescription: true,
        taboolaPixelTracking: false,
        outbrainPixelTracking: false,
        fbPixelTracking: true,
        showAppDetails: false,
        faqsVisible: false,
        showDownloadVideo: false,
        showSnapScript: false,
        showQuoraScript: false,
        gameCategory: "poker",
        installText: "Download App",
        iosRedirect: true,
      },
      affiliate_hotstar: {
        installUrl: "https://www.getmega.com/affiliates-poker-lp-apk/",
        forceUse: true,
        hostingSite: "affiliate-hotstar-download-app-cards", // affiliates-hotstar-poker.getmega.com
        websiteSubdir: "poker-affiliates-hotstar",
        descriptionText:
          "Mega Poker offers you a really one-of-a-kind entertainment experience by combining the fun of gaming with the thrill of real money. You can play your preferred skill-based card game exclusively on Mega Poker, the best real money gaming site in India!!",
        hasLargeDescription: true,
        taboolaPixelTracking: false,
        outbrainPixelTracking: false,
        fbPixelTracking: true,
        showAppDetails: true,
        showDownloadVideo: false,
        hotstarPage: true,
        gameCategory: "poker",
      },
      affiliate_taboola: {
        installUrl: "https://www.getmega.com/affiliates-poker-lp-apk/",
        forceUse: true,
        hostingSite: "megapoker-download-app-cards", // megapoker.getmega.com
        websiteSubdir: "megapoker",
        descriptionText:
          "Mega Poker offers you a really one-of-a-kind entertainment experience by combining the fun of gaming with the thrill of real money. You can play your preferred skill-based card game exclusively on Mega Poker, the best real money gaming site in India!!",
        hasLargeDescription: true,
        taboolaPixelTracking: true,
        outbrainPixelTracking: false,
        fbPixelTracking: false,
        showAppDetails: true,
        showDownloadVideo: false,
        gameCategory: "poker",
      },
      affiliate_ab: {
        installUrl: "https://www.getmega.com/latest",
        forceUse: true,
        hostingSite: "download-affiliate-app-cards",
        showPrivacyPolicy: true,
        taboolaPixelTracking: false,
        outbrainPixelTracking: false,
        fbPixelTracking: true,
        makeReviewsCollapsable: true,
        showAppInfo: true,
        cms: true,
        airtableBaseId: "app63cd0Z5hGSJJMy",
        showDownloadVideo: false,
        gameCategory: "poker",
      },
      affiliate_hindi: {
        language: "hindi",
        getmegaText: "गेटमेगा",
        installText: "इंस्टॉल करें",
        readMoreText: "अधिक पढ़ें",
        collapseText: "ढहने",
        gamesType: "मेगा गेम्स",
        descriptionText:
          "खेलें पत्तों के गेम्स भारत के सबसे चहिते रियल मनी मनोरंजन ऍप पे। यहाँ हर रोज़ 10,000 से ज्यादा खिलाड़ी खेलते हैं। अपनी मर्ज़ी का टेबल चुनें - छोटे से बड़े दांव तक। अनगिनत पैसे कमाने का मौका पाएं।",
        descriptionList: [
          "देश के सबसे बड़े पत्तों के टेबल्स पे खेलें ♠️ ❤️",
          "सिर्फ 100% जांचे हुए प्रोफाइल्स, कोई नकली प्रोफाइल्स नहीं 👩 👨",
          "अपनी मर्ज़ी का दांव चुनें - छोटे, मध्यम, बड़े, और प्रचंड! 🏆",
          "24 घंटे लगातार चलनेवाले टेबल्स पे कभी भी खेलें ⏳",
          "किसी भी समस्या के लिए उच्च क्वालिटी चैट सपोर्ट 👨🏽‍💻",
          "भारत में 100% बनी हुई ऍप का समर्थन करें 🇮🇳",
        ],
        staticTags: ["पत्तों के गेम्स", "जीतें असली कॅश"],
        reviewTags: ["सबसे तेज़ ट्रांस्फर", "सच्चे खिलाड़ी", "आकर्षक"],
        popupTitle: "रिया जीती ₹89,580 पेहले ही हफ्ते में!",
        popupText:
          "बड़े टेबल्स पे पोकर खेलके जीतें बहुत सारा कॅश. अगला टूर्नामेंट शुरू होनेवाला है:",
        popupDownloadText: "आजके डाउनलोड्स:",
        numOfDownloads: "500,000+",
        installUrl: "https://www.getmega.com/latest",
        forceUse: true,
        hostingSite: "affiliate-download-cards-hindi",
        taboolaPixelTracking: false,
        outbrainPixelTracking: false,
        fbPixelTracking: true,
        totalReviews: "10,000 रेटिंग",
        downloadsText: "डाउनलोड्स",
        ratingsHeading: "रेटिंग और रिव्यु",
        mostMentionedText: "रिव्यु में सबसे ज्यादा ज़िक्र:",
        gameplayText: "गेमप्ले",
        controlsText: "कंट्रोल",
        graphicsText: "ग्राफ़िक्स",
        disclaimerText:
          "इस खेल में वित्तीय जोखिम का एक तत्व शामिल है और यह व्यसनी हो सकता है। कृपया जिम्मेदारी से और अपने जोखिम पर खेलें।",
        privacyPolicyText: "गोपनीयता नीति",
        todayDownloadsText: "आजके डाउनलोड्स",
        joinNowText: "अब शामिल हों",
        showDownloadVideo: false,
        gameCategory: "poker",
      },
      facebook_hindi: {
        language: "hindi",
        getmegaText: "गेटमेगा",
        installText: "इंस्टॉल करें",
        readMoreText: "अधिक पढ़ें",
        collapseText: "ढहने",
        gamesType: "मेगा गेम्स",
        descriptionText:
          "खेलें पत्तों के गेम्स भारत के सबसे चहिते रियल मनी मनोरंजन ऍप पे। यहाँ हर रोज़ 10,000 से ज्यादा खिलाड़ी खेलते हैं। अपनी मर्ज़ी का टेबल चुनें - छोटे से बड़े दांव तक। अनगिनत पैसे कमाने का मौका पाएं।",
        descriptionList: [
          "देश के सबसे बड़े पत्तों के टेबल्स पे खेलें ♠️ ❤️",
          "सिर्फ 100% जांचे हुए प्रोफाइल्स, कोई नकली प्रोफाइल्स नहीं 👩 👨",
          "अपनी मर्ज़ी का दांव चुनें - छोटे, मध्यम, बड़े, और प्रचंड! 🏆",
          "24 घंटे लगातार चलनेवाले टेबल्स पे कभी भी खेलें ⏳",
          "किसी भी समस्या के लिए उच्च क्वालिटी चैट सपोर्ट 👨🏽‍💻",
          "भारत में 100% बनी हुई ऍप का समर्थन करें 🇮🇳",
        ],
        staticTags: ["पत्तों के गेम्स", "जीतें असली कॅश"],
        reviewTags: ["सबसे तेज़ ट्रांस्फर", "सच्चे खिलाड़ी", "आकर्षक"],
        popupTitle: "रिया जीती ₹89,580 पेहले ही हफ्ते में!",
        popupText:
          "बड़े टेबल्स पे पोकर खेलके जीतें बहुत सारा कॅश. अगला टूर्नामेंट शुरू होनेवाला है:",
        popupDownloadText: "आजके डाउनलोड्स:",
        numOfDownloads: "500,000+",
        installUrl:
          "https://affiliate_asiasouth1.getmegacdn.com/latest-fb-poker-hindi",
        forceUse: true,
        hostingSite: "download-app-cards-hindi",
        taboolaPixelTracking: false,
        outbrainPixelTracking: false,
        fbPixelTracking: true,
        totalReviews: "10,000 रेटिंग",
        downloadsText: "डाउनलोड्स",
        ratingsHeading: "रेटिंग और रिव्यु",
        mostMentionedText: "रिव्यु में सबसे ज्यादा ज़िक्र:",
        gameplayText: "गेमप्ले",
        controlsText: "कंट्रोल",
        graphicsText: "ग्राफ़िक्स",
        disclaimerText:
          "इस खेल में वित्तीय जोखिम का एक तत्व शामिल है और यह व्यसनी हो सकता है। कृपया जिम्मेदारी से और अपने जोखिम पर खेलें।",
        privacyPolicyText: "गोपनीयता नीति",
        todayDownloadsText: "आजके डाउनलोड्स",
        joinNowText: "अब शामिल हों",
        showDownloadVideo: false,
        gameCategory: "poker",
      },
      facebook_marathi: {
        language: "marathi",
        getmegaText: "गेटमेगा",
        installText: "स्थापित करा",
        readMoreText: "पुढे वाचा",
        collapseText: "कोसळणे",
        gamesType: "मेगा गेम्स",
        descriptionText:
          "खेळा पत्त्यांचे गेम्स भारतातील सर्वांत लोकप्रिय रियल मनी मनोरंजन ऍपवर. इथे रोज 10,000+ खेळाडू खेळतात. आपल्या मर्जीनुसार टेबल निवडा - लहानापासून मोठ्या स्टेक्स पर्यंत. अमर्याद रक्कम जिंकण्याची संधी सोडू नका.",
        descriptionList: [
          "भारतातील सर्वात मोठ्या पत्त्यांच्या टेबल्स वर खेळा ♠️ ❤️",
          "फक्त 100% तपासणी झालेल्या खेळाडूंशीच खेळा, फेक प्रोफाईल्स नाहीत 👩 👨",
          "तुम्हाला हवे असलेलेच स्टेक्स निवडा - लहान, मध्यम, मोठे, आणि खूप मोठे 🏆",
          "24 तास सतत चालणाऱ्या टेबल्सवर कधीही खेळा ⏳",
          "कोणत्याही समस्येसाठी सर्वाधिक प्राधान्याची चॅट सपोर्ट 👨🏽‍💻",
          "100% भारतात विकसित झालेल्या ऍपला समर्थन द्या 🇮🇳",
        ],
        staticTags: ["पत्त्यांचे गेम्स", "रोख रक्कम जिंका"],
        reviewTags: [
          "सगळ्यात लवकर पैसे ट्रांसफर",
          "100% अस्सल खेळाडू",
          "आकर्षक",
        ],
        popupTitle: "₹89,580 जिंकले रियाने, पहिल्याच आठवड्यात!",
        popupText:
          "खूप सारी रक्कम जिंकण्यासाठी पोकरच्या मोठ्या टेबल्सवर खेळा. पुढील टूर्नामेंट लवकरच सुरू होत आहे:",
        popupDownloadText: "आजचे  डाउनलोड्स:",
        numOfDownloads: "500,000+",
        installUrl:
          "https://affiliate_asiasouth1.getmegacdn.com/latest-fb-poker-marathi",
        forceUse: true,
        hostingSite: "download-app-cards-marathi",
        taboolaPixelTracking: false,
        outbrainPixelTracking: false,
        fbPixelTracking: true,
        totalReviews: "10,000 रिव्यु",
        downloadsText: "डाउनलोड्स",
        ratingsHeading: "रेटिंग्ज आणि रिव्ह्यूज",
        mostMentionedText: "रिव्ह्यूजमध्ये सर्वाधिक उल्लेख:",
        gameplayText: "गेमप्ले",
        controlsText: "कंट्रोल्स",
        graphicsText: "ग्राफिक्स",
        disclaimerText:
          "या गेममध्ये आर्थिक जोखीम आणि व्यसनाची शक्यता आहे. कृपया जबाबदारीने आणि आपल्या जोखमीवर खेळा.",
        privacyPolicyText: "गोपनीयता धोरण",
        todayDownloadsText: "आजचे  डाउनलोड्स",
        joinNowText: "आता सामील व्हा",
        showDownloadVideo: false,
        gameCategory: "poker",
      },
    },
  },
  rummy: {
    gameName: "Rummy",
    descriptionText:
      "Play Rummy on India’s most loved real money entertainment platform. 10,000+ players are playing every day. Choose your contest / table, small stakes or big stakes. Unlimited Real Cash to be won!",
    descriptionList: [
      "Discover India’s biggest Rummy tables ♠️ ❤️",
      "Play with sirf 100% verified players, no fake profiles 👩 👨",
      "Choose stakes apni marzi ke - small, medium, large, & HUGE 🏆",
      "Khelo kabhi bhi, with non-stop 24-hours tables ⏳",
      "Kisi bhi issue ke liye high-priority chat support 👨🏽‍💻",
      "Support karo 100% made in India app 🇮🇳",
    ],
    staticTags: ["Card", "Rummy", "Win Money"],
    reviewTags: [
      "Fastest withdrawals",
      "UPI",
      "Best Deposit Offers",
      "0 Fraud",
      "Real Players",
      "Best Customer Support",
    ],
    ratingValue: "4.8",
    downloadSize: "45.2 MB",
    popupImage: "rummy-girl.png",
    popupTitle: "Don't miss out on your chance to win big!",
    popupText:
      "Get 500% bonus cash on your 1st deposit. Next tournament starts in:",
    vimeoVideoId: "460421190",
    gtagId: "AW-718511341",
    airtableName: "Poker LP Reviews",
    airtableBaseId: "appzs5lZNAcpHlhyA",
    staticReviews: "rummy",
    numOfDownloads: "500K+",
    showAppDetails: false,
    hasLargeDescription: false,
    makeReviewsCollapsable: true,
    gameCategory: "rummy",
    showGlPokerScript: false,
    largeDescription: `
      <p>Mega Rummy offers great rewards. Whether you're a beginner trying your hands on rummy online, or a cash rummy enthusiast wanting to test your skills, Mega Rummy is the app you're looking for!</p>
      <ul>
      <li>Play rummy card games 24x7</li>
      <li>Win real cash prizes daily</li>
      <li>50 Million + players</li>
      <li>The Most Trusted Rummy Site®</li>
      <li>Instant withdrawals: 100% safe & secure cash rummy online withdrawal</li>
      </ul>
      <p>Online Rummy / Rammy (रमी/रम्मी)  is a popular card game played in India. It is played with 2 to 6 players and a deck of cards. The objective of the game is to form sets and run with the cards in hand, and be the first player to dispose of all cards.<br />Mega Rummy offers a seamless experience to every player, be it a player who downloaded their first rummy game or someone who regularly plays rummy online. Right from the rewards to the gameplay experience.</p>
      <p>As one of the leading rummy game apps in the country, Mega Rummy offers different formats of the game to its players. This includes Points rummy, Pool rummy, Deals Rummy & tournaments.</p>
      <ul>
      <li><strong>Points rummy</strong> - Points rummy is the quickest and the most exciting variant of rummy. It can be played between 2 to 6 players with the player reaching 0 points first winning the game. The amount won depends on the points accumulated by the opponents. You can play from ₹0.05 to ₹20 Point value games on Mega Rummy</li>
      <li><strong>Deals rummy</strong> - Objective in this game is to reach 0 points as quickly as possible as well. The only difference is that while Points rummy is played for just 1 round or deal, Deals rummy goes on for multiple pre-decided rounds.</li>
      <li><strong>Pool rummy</strong> - Format that's based on the 'last player standing' idea. There are different variants within this format - 51, 101 and 201.</li>
      </ul>
      <h3>Unique features of Mega Rummy</h3>
      <ul>
      <li>Get all rummy game variants to choose from- play points, pool 101, pool 201, and  
      Deals Rummy.</li>
      <li>Zero waiting time on all the rummy tables.</li>
      <li>Our platform supports all payment modes, including UPI & bank transfer.</li>
      <li>Send unlimited rummy game referrals to as many friends as you want.</li>
      <li>Unlimited Instant cash withdrawals.</li>
      <li>Get FREE unlimited practice chips to learn how to play rummy.</li>
      <li>Get 24x7 chat support in both English and Hindi.</li>
      <li>VIP players get to request support on call.</li>
      </ul>
      <h3>Offers And Rewards</h3>
      <ul>
      <li>Best deposit offers in Industry: Upto 8X</li>
      <li>Win up to 25000 INR through referrals</li>
      <li>Enter the leaderboards with NO ENTRY FEES</li>
      <li>Win rewards by leading the leaderboard</li>
      <li>Use the code NEW400 and get 400% cashback</li>
      </ul>
      <h3>Responsible gaming & Fair Play on Mega Rummy -</h3>
      <p>Mega Rummy has a best-in-class Responsible gaming mechanism for its players.Our platform is equipped with state-of-the-art security measures to ensure the safety of your personal and financial information. Trust us, your money and data are in safe hands.</p>
      <ul>
      <li>Only players who are 18+ years of age are allowed to play.</li>
      <li>SSL secured Safe, secure, verified payment channels to ensure smooth cash deposit & withdrawal.</li>
      <li>RNG certification ensures that shuffling of the cards is purely random.</li>
      <li>All games played on Mega Rummy are checked for Fair Play protection.</li>
      <li>Regular ever-updating Fairplay policy with stricter measures along with player monitoring.</li>
      <li>Customer support for all players for smooth & faster resolution to help resolve players’ issues faster.</li>
      </ul>
      <p>Download Mega Rummy, a gaming app for winning real money, and win daily cash prizes while enjoying thrilling Rummy games.</p>
    `,
    faqsVisible: true,
    showSnapScript: false,
    showQuoraScript: false,
    variants: {
      default: {
        installUrl: "https://www.getmega.com/rummy-online-lp-apk/",
        forceUse: true,
        hostingSite: "download-app-rummy", // rummy-online.getmega.com
        websiteSubdir: "rummy-online",
        gamesType: "Mega Rummy - Play & Win Real Cash",
        staticTags: ["Rummy", "Cards", "Win Money"],
        showAppDetails: true,
        hasLargeDescription: true,
        showDownloadVideo: false,
        appVersion: "1.8",
        latestRelease: "9 Nov 2023",
        taboolaPixelTracking: false,
        outbrainPixelTracking: false,
        fbPixelTracking: true,
        analyticId: "G-JXGZ5MD2YE",
        gameCategory: "rummy",
        descriptionText:
          "Play Rummy game online | 0 waiting period | Win cash prizes upto 20L daily | Unlimited winnings withdrawals through UPI | Enjoy different variants of Rummy",
      },
      gl: {
        installUrl: "https://www.getmega.com/rummy-lp-apk/",
        forceUse: true,
        hostingSite: "download-app-rummy-gl", // rummy.getmega.com
        websiteSubdir: "rummy-new",
        gamesType: "Mega Rummy - Play & Win Real Cash",
        staticTags: ["Rummy", "Cards", "Win Money"],
        showAppDetails: true,
        hasLargeDescription: true,
        showDownloadVideo: false,
        appVersion: "1.8",
        latestRelease: "9 Nov 2023",
        taboolaPixelTracking: false,
        outbrainPixelTracking: false,
        fbPixelTracking: true,
        analyticId: "G-37KYFT97G8",
        gameCategory: "rummy",
        descriptionText:
          "Play Rummy game online | 0 waiting period | Win cash prizes upto 20L daily | Unlimited winnings withdrawals through UPI | Enjoy different variants of Rummy",
      },
      app1: {
        installUrl: "https://www.getmega.com/rummy-cash-lp-apk/",
        forceUse: true,
        hostingSite: "download-1-app-rummy", // rummy-cash.getmega.com
        websiteSubdir: "rummy-cash",
        gamesType: "Mega Rummy - Play & Win Real Cash",
        staticTags: ["Rummy", "Cards", "Win Money"],
        showAppDetails: true,
        hasLargeDescription: true,
        showDownloadVideo: false,
        appVersion: "1.8",
        latestRelease: "9 Nov 2023",
        taboolaPixelTracking: false,
        outbrainPixelTracking: false,
        fbPixelTracking: true,
        analyticId: "G-9VJJ0RP1T5",
        gameCategory: "rummy",
        descriptionText:
          "Play Rummy game online | 0 waiting period | Win cash prizes upto 20L daily | Unlimited winnings withdrawals through UPI | Enjoy different variants of Rummy",
      },
      app2: {
        installUrl: "https://www.getmega.com/rummy-app-lp-apk/",
        forceUse: true,
        hostingSite: "download-2-app-rummy", // rummy-app.getmega.com
        websiteSubdir: "rummy-app",
        gamesType: "Mega Rummy - Play & Win Real Cash",
        staticTags: ["Rummy", "Cards", "Win Money"],
        showAppDetails: true,
        hasLargeDescription: true,
        showDownloadVideo: false,
        appVersion: "1.8",
        latestRelease: "9 Nov 2023",
        taboolaPixelTracking: false,
        outbrainPixelTracking: false,
        fbPixelTracking: true,
        analyticId: "G-Y5CEHTGKVB",
        gtmTag: "GTM-TZ9SNKV",
        gameCategory: "rummy",
        descriptionText:
          "Play Rummy game online | 0 waiting period | Win cash prizes upto 20L daily | Unlimited winnings withdrawals through UPI | Enjoy different variants of Rummy",
      },
      app3: {
        installUrl: "https://www.getmega.com/rummy-game-lp-apk/",
        forceUse: true,
        hostingSite: "download-3-app-rummy", // rummy-game.getmega.com
        websiteSubdir: "rummy-game",
        gamesType: "Mega Rummy - Play & Win Real Cash",
        staticTags: ["Rummy", "Cards", "Win Money"],
        showAppDetails: true,
        hasLargeDescription: true,
        showDownloadVideo: false,
        appVersion: "1.8",
        latestRelease: "9 Nov 2023",
        taboolaPixelTracking: false,
        outbrainPixelTracking: false,
        fbPixelTracking: true,
        analyticId: "G-026L54RVMF",
        gameCategory: "rummy",
        descriptionText:
          "Play Rummy game online | 0 waiting period | Win cash prizes upto 20L daily | Unlimited winnings withdrawals through UPI | Enjoy different variants of Rummy",
      },
      affiliate: {
        installUrl: "https://www.getmega.com/affiliates-rummy-lp-apk/",
        forceUse: true,
        hostingSite: "affiliate-download-app-rummy", // affiliates-rummy.getmega.com
        websiteSubdir: "rummy-affiliates",
        disclaimer: "This app will be downloaded outside Google Play Store",
        gamesType: "Mega Rummy - Play & Win Real Cash",
        showAppDetails: true,
        hasLargeDescription: true,
        showDownloadVideo: false,
        appVersion: "1.8",
        latestRelease: "9 Nov 2023",
        taboolaPixelTracking: false,
        outbrainPixelTracking: false,
        fbPixelTracking: true,
        gameCategory: "rummy",
        descriptionText:
          "Play Rummy game online | 0 waiting period | Win cash prizes upto 20L daily | Unlimited winnings withdrawals through UPI | Enjoy different variants of Rummy",
      },
      affiliate_hindi: {
        language: "hindi",
        getmegaText: "गेटमेगा",
        installText: "इंस्टॉल करें",
        readMoreText: "अधिक पढ़ें",
        collapseText: "ढहने",
        gamesType: "रियल मनी गेम्स",
        descriptionText:
          "भारत के सबसे चहिते रियल मनी मनोरंजन ऍप पे रमी खेलें। यहाँ हर रोज़ 10,000 से ज्यादा खिलाडी खेलते हैं। अपनी मर्ज़ी का टेबल चुनें - छोटे से बड़े दांव तक। अनगिनत पैसे कमाने का मौका पाएं।",
        descriptionList: [
          "देश के सबसे बड़े टेबल्स पे खेलें ♠️ ❤️",
          "सिर्फ 100% जांचे हुए प्रोफाइल्स, कोई नकली प्रोफाइल्स नहीं 👩 👨",
          "अपनी मर्ज़ी का दांव चुनें - छोटे, मध्यम, बड़े, और प्रचंड! 🏆",
          "24 घंटे लगातार चलनेवाले टेबल्स पे कभी भी खेलें ⏳",
          "किसी भी समस्या के लिए उच्च अग्रता का चैट सपोर्ट 👨🏽‍💻",
          "भारत में 100% बनी हुई ऍप का समर्थन करें 🇮🇳",
        ],
        staticTags: ["पत्तों के गेम्स", "जीतें असली कॅश"],
        reviewTags: ["सबसे तेज़ ट्रांस्फर", "सच्चे खिलाड़ी", "आकर्षक"],
        popupTitle: "रिया जीती ₹89,580 पेहले ही हफ्ते में!",
        popupText:
          "बड़े टेबल्स पे रमी खेलके जीतें बहुत सारा कॅश. अगला टूर्नामेंट शुरू होनेवाला है:",
        popupDownloadText: "आजके डाउनलोड्स:",
        vimeoVideoId: "460421190",
        numOfDownloads: "500,000+",
        installUrl: "https://getmega.onelink.me/shOD/SEOListing",
        forceUse: true,
        hostingSite: "affiliate-download-rummy-hindi",
        taboolaPixelTracking: false,
        outbrainPixelTracking: false,
        fbPixelTracking: true,
        totalReviews: "10,000 रेटिंग",
        downloadsText: "डाउनलोड्स",
        ratingsHeading: "रेटिंग और रिव्यु",
        mostMentionedText: "रिव्यु में सबसे ज्यादा ज़िक्र:",
        gameplayText: "गेमप्ले",
        controlsText: "कंट्रोल",
        graphicsText: "ग्राफ़िक्स",
        disclaimerText:
          "इस खेल में वित्तीय जोखिम का एक तत्व शामिल है और यह व्यसनी हो सकता है। कृपया जिम्मेदारी से और अपने जोखिम पर खेलें।",
        privacyPolicyText: "गोपनीयता नीति",
        todayDownloadsText: "आजके डाउनलोड्स",
        joinNowText: "अब शामिल हों",
        showDownloadVideo: false,
        gameCategory: "rummy",
      },
      affiliate_getmegarummy: {
        installUrl:
          "https://mega-prod-assets.getmega.com/app-releases/grid_logic/GetMega_Prod_v0.9.1-6.apk",
        forceUse: true,
        hostingSite: "affiliate-download-app-rummy-getmegarummy",
        disclaimer: "This app will be downloaded outside Google Play Store",
        taboolaPixelTracking: false,
        outbrainPixelTracking: false,
        fbPixelTracking: true,
        gamesType: "Online Rummy Games",
        showAppDetails: true,
        hasLargeDescription: true,
        descriptionText:
          "Unlike any other form of entertainment, Mega Rummy combines the pleasure and excitement of playing for real money. Psyched up for trying out your rummy skills, look no further. Mega Rummy covers all your needs. A platform trusted by millions of players; dont wait anymore. Just hit the download button and join this fantastic community. Go and check out the existing Mega Rummy page!",
        showDownloadVideo: false,
        gameCategory: "rummy",
      },
      affiliate_2_getmegarummy: {
        installUrl:
          "https://mega-prod-assets.getmega.com/app-releases/grid_logic/GetMega_Prod_v0.9.1-6.apk",
        forceUse: true,
        hostingSite: "download-app-rummy-getmegarummy",
        disclaimer: "This app will be downloaded outside Google Play Store",
        taboolaPixelTracking: false,
        outbrainPixelTracking: false,
        fbPixelTracking: true,
        gamesType: "Online Rummy Games",
        showAppDetails: true,
        hasLargeDescription: true,
        descriptionText:
          "Unlike any other form of entertainment, Mega Rummy combines the pleasure and excitement of playing for real money. Psyched up for trying out your rummy skills, look no further. Mega Rummy covers all your needs. A platform trusted by millions of players; dont wait anymore. Just hit the download button and join this fantastic community. Go and check out the existing Mega Rummy page!",
        showDownloadVideo: false,
        gameCategory: "rummy",
      },
    },
  },
}

module.exports = pages
