import React from "react"
import Description from "./Description"
import "../styles/carousel.css"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
// import "slick-carousel/slick/slick-theme.css"
import Gallery from "./Gallery"
import ConfigContext from "./utils/configContext"
import withLocation from "./withLocation"
import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

class Carousel extends React.Component {
  static contextType = ConfigContext

  state = {
    desc: this.context.descriptionText,
    showGallery: false,
    slideToScroll: 1,
    showFullContent: false,
    imageGallery: [],
  }

  showGallery = (e, index) => {
    e.preventDefault()
    e.stopPropagation()
    window?.gtag?.("event", "Carousel", {
      event_category: "Opened Fullscreen image",
      event_label: index,
      value: 1,
    })
    this.setState({ showGallery: true, slideToScroll: index })
  }

  hideGallery = () => {
    this.setState({ showGallery: false })
  }

  toggleShowFullContent = () => {
    this.setState(state => {
      return { showFullContent: !state.showFullContent }
    })
  }

  render() {
    let description = this.state.desc

    let { showGallery, slideToScroll } = this.state

    const bannerImages = graphql`
      {
        rummyBanners: allFile(
          filter: {
            extension: { regex: "/(jpg)|(jpeg)|(png)/" }
            relativeDirectory: { glob: "banners/rummy/**" }
          }
          sort: { fields: name }
        ) {
          edges {
            node {
              relativePath
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 875
                  breakpoints: [400, 600, 875]
                )
              }
            }
          }
        }
        pokerAndroidBanners: allFile(
          filter: {
            extension: { regex: "/(jpg)|(jpeg)|(png)/" }
            relativeDirectory: { glob: "banners/poker/android/**" }
          }
          sort: { fields: name }
        ) {
          edges {
            node {
              relativePath
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 280
                  breakpoints: [280, 400]
                )
              }
            }
          }
        }
        pokerIosBanners: allFile(
          filter: {
            extension: { regex: "/(jpg)|(jpeg)|(png)/" }
            relativeDirectory: { glob: "banners/poker/ios/**" }
          }
          sort: { fields: name }
        ) {
          edges {
            node {
              relativePath
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 280
                  breakpoints: [280, 400]
                )
              }
            }
          }
        }
      }
    `

    const gameCat = this.context.gameCategory
    const settings = {
      dots: false,
      arrows: false,
      infinite: false,
      speed: 500,
      slidesToShow: gameCat === "rummy" ? 1.5 : 5,
      responsive: [
        {
          breakpoint: 900,
          settings: {
            slidesToShow: gameCat === "rummy" ? 1.2 : 4.5,
          },
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: gameCat === "rummy" ? 1.2 : 3.2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: gameCat === "rummy" ? 1.2 : 2.5,
          },
        },
      ],
    }

    return (
      <>
        <div className="container padTop1">
          <StaticQuery
            query={bannerImages}
            render={data => {
              const pokerBanners = this.context.gameDevice === 'ios' ? data.pokerIosBanners.edges : data.pokerAndroidBanners.edges
              const pageBanners =
                gameCat === "rummy"
                  ? data.rummyBanners.edges
                  : pokerBanners

              const bannerWidth = gameCat === "rummy" ? 400 : 280

              const bannerLazyItem = gameCat === "rummy" ? 2 : 3

              return (
                <div className={`siema ${this.context.gameCategory}`}>
                  <Slider ref={slider => (this.slider = slider)} {...settings}>
                    {pageBanners.map((item, i) => (
                      <div
                        key={i}
                        className="padR4"
                        onClick={e => this.showGallery(e, i)}
                        onKeyDown={e => this.showGallery(e, i)}
                        role="button"
                        tabIndex="0"
                      >
                        <GatsbyImage
                          image={item.node.childImageSharp.gatsbyImageData}
                          alt={gameCat}
                          width={bannerWidth}
                          loading={i > bannerLazyItem ? "lazy" : "lazy"}
                        />
                      </div>
                    ))}
                  </Slider>
                </div>
              )
            }}
          />

          <div className=" is-size-7">
            <p>{description}</p>
            <br />
          </div>
          <Description />
          {this.context.showAppDetails && (
            <div className="app-details">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <span>Version</span>
                      {this.context.appVersion || "7.0.5"}
                    </td>

                    <td>
                      <span>Updated on</span>
                      {this.context.latestRelease || "19 Sep 2022"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Required Android</span>6.0 and above
                    </td>

                    <td>
                      <span>Downloads</span>
                      {this.context.numOfDownloads}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Content rating</span>18+
                    </td>

                    <td>
                      <span>Released on</span>19 Sep 2022
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Offered by</span>Mega {this.context.gameName}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
        {showGallery ? (
          <Gallery
            slideToScroll={slideToScroll}
            hideGallery={this.hideGallery}
            fbEventAndShowModal={this.props.fbEventAndShowModal}
            togglePrompt={this.props.togglePrompt}
            toggleModal={this.props.toggleModal}
            isChrome={this.props.isChrome}
            installUrl={this.props.installUrl}
          />
        ) : null}
      </>
    )
  }
}

export default withLocation(Carousel)
