import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import App from "../components/App"
import ConfigContext from "../components/utils/configContext"
import pages from "../pagesConfigs"

const IndexPage = () => {
  const page = pages[process.env.PAGE]
  const variant = process.env.VARIANT

  let config = (({ variants, ...o }) => o)(page)

  if (page?.variants?.hasOwnProperty(variant)) {
    config = { ...config, ...page.variants[variant] }
  } else {
    config = { ...config, ...page.variants["default"] }
  }

  return (
    <Layout>
      <Seo title={`Mega ${process.env.PAGE === "cards" ? "Poker" : "Rummy"}`} />
      <ConfigContext.Provider value={config}>
        <App />
      </ConfigContext.Provider>
    </Layout>
  )
}

export default IndexPage
