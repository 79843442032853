import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import CloseButton from "../images/close.svg"

import "../styles/Popup.css"
import ConfigContext from "./utils/configContext"

const Images = graphql`
  {
    heroes: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        relativeDirectory: { glob: "popup/hero/**" }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`

class Popup extends React.Component {
  static contextType = ConfigContext

  constructor(props) {
    super(props)

    this.state = {
      minutes: "01",
      seconds: "30",
      downloadsCount: 3598,
      oneLink: "",
    }
  }

  componentDidMount() {
    // start timer for 1.5 minutes
    this.startTimer(60 * 1.5)
  }

  componentWillUnmount() {
    clearInterval(this.state.countdownTimer)
  }

  startTimer = duration => {
    let timer = duration,
      minutes,
      seconds

    const countdownTimer = setInterval(() => {
      minutes = parseInt(timer / 60, 10)
      seconds = parseInt(timer % 60, 10)

      this.setState({
        minutes: minutes < 10 ? "0" + minutes : minutes,
        seconds: seconds < 10 ? "0" + seconds : seconds,
        downloadsCount:
          this.state.downloadsCount + (seconds % 10 === 0 ? 1 : 0),
      })

      if (--timer < 0) {
        this.props.closePopup()
      }
    }, 1000)
    this.setState({ countdownTimer })
  }

  nonChromeFunc = () => {
    this.props.toggleModal()
    setTimeout(() => {
      window.location = this.state.oneLink || this.props.installUrl
    }, 1100)
  }

  onClickHandler = eventSource => {
    this.props.fbEventAndShowModal(eventSource)
    this.props.isChrome ? this.props.togglePrompt() : this.nonChromeFunc()
    this.props.closePopup()
  }

  render() {
    const { closePopup, customClass } = this.props
    const { minutes, seconds, downloadsCount } = this.state

    const todayDownloadText =
      this.context.todayDownloadsText || "Today's downloads"

    return (
      <div id="backdrop" className={customClass + ' ' + this.context.gameName}>
        <div className="bg">
          <div id="popup" className={this.context.gameName}>
            <div className="close">
              <div
                onClick={closePopup}
                onKeyDown={closePopup}
                role="button"
                tabIndex="0"
              >
                <img
                  src={CloseButton}
                  alt="close button"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            {this.context.gameName === "Rummy" ||
              this.context.gameName === "Poker" ? (
              <>
                <button
                  className="pop-btn install_button"
                  type="button"
                  data-onelink=""
                  onClick={e => {
                    this.setState({
                      oneLink: e.target.getAttribute("data-onelink"),
                    })
                    this.onClickHandler("Popup Clicked")
                  }}
                >
                  {this.context.gameName === "Rummy" ? (
                    <StaticImage
                      src="../images/popup/rummy.jpg"
                      alt="rummy"
                      width={500}
                    />
                  ) : (
                    <StaticImage
                      src="../images/popup/poker.jpg"
                      alt="poker"
                      width={500}
                    />
                  )}
                </button>
                {this.context.hotstarPage && (
                  <p className="download-bottom-text">
                    Allow Outside Google Playstore App Download
                  </p>
                )}
              </>
            ) : (
              <>
                <span
                  style={{
                    fontSize: "15px",
                    textAlign: "center",
                    whiteSpace: "nowrap",
                  }}
                >
                  {this.context.popupTitle}
                </span>
                <span className="subtext">{this.context.popupText}</span>
                <div className="timer">
                  <span>00:</span>
                  {minutes}
                  <span>:</span>
                  {seconds}
                </div>
                <StaticQuery
                  query={Images}
                  render={data => {
                    let fluidImgFromQuery = []
                    for (let key in data.heroes.edges) {
                      fluidImgFromQuery.push({
                        img: data.heroes.edges[key].node.childImageSharp
                          .gatsbyImageData,
                        path: data.heroes.edges[key].node.relativePath.replace(
                          "popup/hero/",
                          ""
                        ),
                      })
                    }

                    const fluid = fluidImgFromQuery.find(
                      f => f.path === this.context.popupImage
                    )

                    return (
                      <div
                        style={{
                          width: "212px",
                        }}
                      >
                        <div>
                          <GatsbyImage image={fluid.img} alt="" />
                        </div>
                        <div style={{ marginTop: "16px" }}>
                          <button
                            type="button"
                            data-onelink=""
                            onClick={e => {
                              this.setState({
                                oneLink: e.target.getAttribute("data-onelink"),
                              })
                              this.onClickHandler("Popup Title Button")
                            }}
                            className="popup-install-btn install_button"
                          >
                            {this.context.joinNowText || "Play Now"}
                          </button>
                        </div>
                      </div>
                    )
                  }}
                />
                <span
                  style={{
                    marginTop: "16px",
                    fontFamily: '"Gotham Rounded Book" !important',
                  }}
                  className="downloads"
                >
                  {todayDownloadText} : {downloadsCount}
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default Popup
